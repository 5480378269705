import './footer.styles.scss';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer-content'>
        <div className='footer-logo-container'>
          <img
            className='footer-logo'
            src='/images/logos/logo.svg'
            alt='Jade Hare logo'
          />
        </div>

        <div className='footer-text-container'>
          <div className='footer-contacts'>
            <p className='footer-heading'>Contact Us</p>

            <address className='contacts-address'>
              <p>Kyle Zhang</p>

              <p className='tel'>
                <a className='footer-link' href='tel:+1 437-239-6585'>
                  +1 437-239-6585
                </a>
              </p>

              <p className='email'>
                <a className='footer-link' href='mailto:info@jadehare.us'>
                  info@jadehare.us
                </a>
              </p>
            </address>
          </div>

          <div className='footer-resources'>
            <p className='footer-heading'>Resources</p>
            <ul className='footer-nav'>
              <li>
                <a href='/about' className='footer-link' target='_blank'>
                  About Us
                </a>
              </li>
              <li>
                <a href='/terms' className='footer-link' target='_blank'>
                  Terms & Conditions
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import ProductItem from '../product-item/product-item.component';

import './product-list.styles.scss';

const ProductList = ({ products }) => {
  return (
    <ul className='product-list'>
      {products.map((product) => (
        <li key={product.id}>
          <ProductItem product={product} />
        </li>
      ))}
    </ul>
  );
};

export default ProductList;

import { Routes, Route } from 'react-router-dom';

import Welcome from './routes/welcome/welcome.component';
import Home from './routes/home/home.component';
import ProductDetail from './routes/product-detail/product-detail.component';
import Terms from './routes/terms/terms.component';
import About from './routes/about/about.component';

import './App.css';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Welcome />}></Route>
      <Route path='home' element={<Home />}></Route>
      <Route path='product/:id' element={<ProductDetail />}></Route>
      <Route path='about' element={<About />}></Route>
      <Route path='terms' element={<Terms />}></Route>
    </Routes>
  );
}

export default App;

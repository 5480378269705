import './culture-item.styles.scss';

const CultureItem = ({ culture }) => {
  const { header, description, imageUrl, imageAltText, iconName } = culture;

  return (
    <div className='culture-item'>
      <div className='culture-item-image-container'>
        <img src={imageUrl} alt={imageAltText} className='culture-item-image' />
      </div>

      <div className='culture-item-text-container'>
        <div className='culture-item-icon-container'>
          <span className='material-symbols-outlined culture-item-icon'>
            {iconName}
          </span>
        </div>

        <h3 className='heading-tertiary culture-item-heading'>{header}</h3>

        <p className='culture-item-description'>{description}</p>
      </div>
    </div>
  );
};

export default CultureItem;

export const randomInt = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min)) + min;
};

export const randomIndexes = (
  total: number,
  numOfIndexes: number = 0,
  excludedIndex: number = -1
) => {
  if (numOfIndexes <= 0) return [];

  if (numOfIndexes >= total) {
    return Array(total)
      .fill('')
      .map((_, i) => i);
  }

  const result = [];

  while (result.length < numOfIndexes) {
    const randomIndex = randomInt(0, total);
    if (randomIndex !== excludedIndex && !result.includes(randomIndex)) {
      result.push(randomIndex);
    }
  }

  return result;
};

import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { ProductsContext } from '../../context/products.context';

import './welcome.styles.scss';

const defaultDate = dayjs();

const Welcome = () => {
  const navigate = useNavigate();

  const { locations, legalAge } = useContext(ProductsContext);

  const [date, setDate] = useState(defaultDate);
  const [province, setProvince] = useState(null);

  const [valid, setValid] = useState(false);

  const isOldEnough = (dob, targetAge) => {
    const birthday = new Date(dob);
    const today = new Date();

    const age = today.getFullYear() - birthday.getFullYear();

    const hasBirthdayOccurred =
      today.getMonth() > birthday.getMonth() ||
      (today.getMonth() === birthday.getMonth() &&
        today.getDate() >= birthday.getDate());

    const finalAge = hasBirthdayOccurred ? age : age - 1;

    return finalAge >= targetAge;
  };

  useEffect(() => {
    if (isOldEnough(date, legalAge) && Boolean(province)) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [date, province, legalAge]);

  const handleDateChange = (value) => {
    setDate(value);
  };

  const handleProvinceChange = (event) => {
    setProvince(event.target.value);
  };

  const handleButtonClick = () => {
    navigate('/home');
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className='page-welcome'>
        <div className='welcome-card'>
          <h1 className='heading heading-primary card-heading'>
            Welcome to Jade Hare
          </h1>

          <div className='user-information'>
            <div className='input-group'>
              <label id='label-select-date' className='input-label' htmlFor=''>
                Select your date of birth
              </label>

              <DatePicker
                sx={{ width: '100%' }}
                value={date}
                onChange={handleDateChange}
                disableFuture
              />
            </div>

            <div className='input-group'>
              <label
                id='label-select-province'
                className='input-label'
                htmlFor='input-select-province'
              >
                Select your province
              </label>

              <Select
                sx={{ width: '100%' }}
                labelId='label-select-province'
                value={province || ''}
                onChange={handleProvinceChange}
              >
                {locations &&
                  locations.map(({ abbr, location }) => (
                    <MenuItem key={abbr} value={abbr}>
                      {location}
                    </MenuItem>
                  ))}
              </Select>
            </div>
          </div>

          <div className='confirmation'>
            <p className='text-warning'>
              Jade Hare is a form of Nicotine Replacement Therapy. Jade Hare
              products are not suitable for use by persons under {legalAge}{' '}
              years of age. Please confirm that you are at least {legalAge}{' '}
              years of age.
            </p>

            <button
              disabled={!valid}
              type='button'
              className='btn btn-primary btn-confirm'
              onClick={handleButtonClick}
            >
              YES, I AM {legalAge}+
            </button>

            <p className='text-terms'>
              By clicking YES I AM {legalAge}+, you agree to our{' '}
              <a className='link-terms' href='/terms' target='_blank'>
                Terms and Policy
              </a>
            </p>
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default Welcome;

import { useContext, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ProductsContext } from '../../context/products.context';
import Header from '../../components/header/header.component';
import Footer from '../../components/footer/footer.component';
import ProductList from '../../components/product-list/product-list.component';

import './product-detail.styles.scss';

const ProductDetail = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { id } = useParams();
  const navigate = useNavigate();

  const { products, generateReferredProducts } = useContext(ProductsContext);
  const productIndex = parseInt(id) - 1;
  const data = products[productIndex];
  const referredProducts = generateReferredProducts(productIndex);

  const handleBackToProducts = () => {
    navigate('/home#products');
  };

  return (
    <>
      <Header theme='dark' />

      <div className='product-details-container container'>
        <span className='btn-back' onClick={handleBackToProducts}>
          Back to Products
        </span>

        <div className='product-details'>
          <div className='product-image-container'>
            <img
              src={data?.url}
              alt={data?.altText}
              className='product-image'
            />
          </div>

          <div className='product-info'>
            <span className='product-title'>{data?.name}</span>

            <div className='product-specs'>
              <span className='product-specs-heading'>Size</span>
              <span className='product-specs-content'>{data?.size}</span>
              <span className='product-specs-heading'>Flavor</span>
              <span className='product-specs-content'>{data?.flavor}</span>
            </div>

            <p className='product-description'>
              Jade Hare is a form of Nicotine Replacement Therapy. It is an
              alternative to smoking, providing a discreet and potentially less
              harmful way for individuals to get their nicotine fix.
            </p>
          </div>
        </div>

        <div className='referral'>
          <span className='referral-heading'>You may also like</span>
          <ProductList products={referredProducts} />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ProductDetail;

import { useContext } from 'react';

import { ProductsContext } from '../../context/products.context';
import Header from '../../components/header/header.component';
import ProductList from '../../components/product-list/product-list.component';
import CultureList from '../../components/culture-list/culture-list.component';
import Footer from '../../components/footer/footer.component';

import './home.styles.scss';

const Home = () => {
  const { products } = useContext(ProductsContext);

  return (
    <>
      <Header theme='light' />

      <main className='main'>
        <section id='hero' className='section section-hero'>
          <div className='container hero-container'>
            <div className='hero-image-container'>
              <img
                src='/images/hero-product.png'
                alt='Jade Hare grape pouch'
                className='hero-image'
              />
            </div>

            <div className='hero-text-container'>
              <h1 className='heading heading-primary hero-heading'>
                Unleash Refreshment, Embrace Clarity: Your Nicotine Escape,
                Anytime, Anywhere
              </h1>

              <p className='hero-slogan'>
                Jade Hare the new way to help control your cravings fast,
                anytime, anywhere
              </p>

              <p className='hero-warning'>
                * WARNING: This product contains nicotine. Nicotine is an
                addictive chemical.
              </p>

              <a href='#products' className='btn btn-primary hero-cta'>
                Explore Our Pouches
              </a>
            </div>
          </div>
        </section>

        <section id='products' className='section section-product-list'>
          <div className='container'>
            <h2 className='products-heading heading heading-secondary text-align-center'>
              Take a deep dive into Jade Hare pouches
            </h2>
            <ProductList products={products} />
          </div>
        </section>

        <section id='why' className='section section-why'>
          <div className='container'>
            <h2 className='why-heading heading heading-secondary text-align-center'>
              Why Jade Hare
            </h2>

            <CultureList />
          </div>
        </section>

        <section id='about' className='section section-about'>
          <div className='container'>
            <h2 className='about-heading heading heading-secondary text-align-center'>
              About Jade Hare
            </h2>

            <div className='about-text-container'>
              <p className='about-text'>
                Jade Hare, established in 2021, is dedicated to providing adult
                users with a nicotine replacement product. The company's primary
                strategic objective is to address tobacco use on a global scale.
                Our mission is to assist individuals who smoke in their journey
                to quit or minimize the adverse effects of nicotine addiction.
                Simultaneously, we strive to safeguard the well-being of youth
                and non-tobacco users by mitigating the potential dangers
                associated with tobacco use and nicotine addiction. By promoting
                healthier alternatives, our aim is to contribute to the
                reduction of health risks and potentially save lives.
              </p>
              <p className='about-text'>
                Our brand Jade Hare gives the consumers a wide range of choices
                of both strength and flavor.
                <br />
                <br />
                Nicotine pouches are typically marketed as tobacco-free
                alternatives to smoking or other forms of tobacco use. They come
                in a variety of flavors and strengths. They are popular among
                individuals who are trying to quit smoking or reduce their
                nicotine intake, as well as those who are looking for a discreet
                way to consume nicotine without the need for smoking or other
                tobacco products.
              </p>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default Home;

import './about.styles.scss';

const About = () => {
  return (
    <div className='page-about'>
      <h1 className='heading heading-primary'>About Jade Hare</h1>

      <div className=''>
        <p>
          Jade Hare, established in 2021, is dedicated to providing adult users
          with a nicotine replacement product. The company's primary strategic
          objective is to address tobacco use on a global scale. Our mission is
          to assist individuals who smoke in their journey to quit or minimize
          the adverse effects of nicotine addiction. Simultaneously, we strive
          to safeguard the well-being of youth and non-tobacco users by
          mitigating the potential dangers associated with tobacco use and
          nicotine addiction. By promoting healthier alternatives, our aim is to
          contribute to the reduction of health risks and potentially save
          lives.
        </p>
        <p>
          Our brand Jade Hare gives the consumers a wide range of choices of
          both strength and flavor.
        </p>
        <p>
          Nicotine pouches are typically marketed as tobacco-free alternatives
          to smoking or other forms of tobacco use. They come in a variety of
          flavors and strengths. They are popular among individuals who are
          trying to quit smoking or reduce their nicotine intake, as well as
          those who are looking for a discreet way to consume nicotine without
          the need for smoking or other tobacco products.
        </p>
      </div>
    </div>
  );
};

export default About;

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ListItemButton, ListItemText } from '@mui/material';

import './header.styles.scss';

const Header = ({ theme }) => {
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const navigate = useNavigate();

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const navigateTo = (url: string): void => {
    navigate(url);
  };

  return (
    <header className={`header ${theme}`}>
      <div className='header-content'>
        <div className='logo-container'>
          <img
            className='logo-image'
            src={`/images/logos/logo-image-${theme}.svg`}
            alt=''
          />
          <img
            className='logo-text'
            src={`/images/logos/logo-text-${theme}.svg`}
            alt=''
          />
        </div>

        <nav className='main-nav'>
          <ul className='main-nav-list'>
            <li className='main-nav-item'>
              <a
                href='/home#products'
                onClick={() => navigateTo('/home#products')}
                className={`main-nav-link ${theme}`}
              >
                Products
              </a>
            </li>
            <li className='main-nav-item'>
              <a
                href='/home#why'
                className={`main-nav-link ${theme}`}
                onClick={() => navigateTo('/home#why')}
              >
                Why Jade Hare
              </a>
            </li>
            <li className='main-nav-item'>
              <a
                href='/home#about'
                className={`main-nav-link ${theme}`}
                onClick={() => navigateTo('/home#about')}
              >
                About
              </a>
            </li>
          </ul>
        </nav>

        <button
          type='button'
          className='nav-btn'
          onClick={toggleDrawer('right', true)}
        >
          <span className='material-symbols-outlined nav-btn-icon'>menu</span>
        </button>
        <Drawer
          anchor='right'
          open={state['right']}
          onClose={toggleDrawer('right', false)}
        >
          <Box
            sx={{ width: 250 }}
            onClick={toggleDrawer('right', false)}
            onKeyDown={toggleDrawer('right', false)}
          >
            <List>
              <ListItem key='home' disablePadding>
                <ListItemButton onClick={() => navigateTo('/home#products')}>
                  <ListItemText primary='Home page' />
                </ListItemButton>
              </ListItem>

              <ListItem key='about' disablePadding>
                <ListItemButton onClick={() => navigateTo('/about')}>
                  <ListItemText primary='About Jade Hare' />
                </ListItemButton>
              </ListItem>

              <ListItem key='terms' disablePadding>
                <ListItemButton onClick={() => navigateTo('/terms')}>
                  <ListItemText primary='Terms and Conditions' />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Drawer>
      </div>
    </header>
  );
};

export default Header;

import { useContext } from 'react';

import { ProductsContext } from '../../context/products.context';
import CultureItem from '../culture-item/culture-item.component';

import './culture-list.styles.scss';

const CultureList = () => {
  const { cultures } = useContext(ProductsContext);

  return (
    <ul className='culture-list'>
      {cultures.map((culture) => (
        <li key={culture.id}>
          <CultureItem culture={culture} />
        </li>
      ))}
    </ul>
  );
};

export default CultureList;

import { useNavigate } from 'react-router-dom';

import './product-item.styles.scss';

const ProductItem = ({ product }) => {
  const { id, name, url, altText } = product;

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/product/${id}`);
  };

  return (
    <div className='product-item' onClick={handleClick}>
      <img className='product-item-image' src={url} alt={altText} />

      <span className='product-item-name'>{name}</span>

      <span className='btn btn-primary btn-fullwidth'>Explore</span>
    </div>
  );
};

export default ProductItem;

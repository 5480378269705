import { createContext, useEffect, useState } from 'react';

import { randomIndexes } from '../utils/helperFunctions.utils';

const country: 'CA' | 'US' = 'US';

type Product = {
  id: 'string',
  name: 'string',
  url: 'string',
  altText: 'string',
  flavor: 'string',
  size: 'string',
};

const generateFourRandomProducts = (
  productList: Product[],
  excludedIndex: number
): Product[] => {
  const random4Indexes = randomIndexes(productList.length, 4, excludedIndex);
  const result = [];

  productList.forEach((product, index) => {
    if (random4Indexes.includes(index)) {
      result.push(product);
    }
  });

  return result;
};

export const ProductsContext = createContext({
  products: [],
  setProducts: () => {},
  cultures: [],
  setCultures: () => {},
  locations: [],
  setLocations: () => {},
  legalAge: 0,
  setLegalAge: () => {},
  generateReferredProducts: (num: number): Product[] => [],
});

export const ProductsProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [cultures, setCultures] = useState([]);
  const [locations, setLocations] = useState([]);
  const [legalAge, setLegalAge] = useState(0);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await fetch('../data/products.json');
        const data = await response.json();
        setProducts(data);
      } catch (err) {
        console.error('Error occurs during fetching product data: ', err);
      }
    };

    const fetchCultureData = async () => {
      try {
        const response = await fetch('/data/cultures.json');
        const data = await response.json();
        setCultures(data);
      } catch (err) {
        console.error('Error occurs during fetching culture data: ', err);
      }
    };

    const fetchLocationData = async () => {
      try {
        const response = await fetch('/data/countries.json');
        const data = await response.json();
        setLocations(data[country]['locations']);
        setLegalAge(Number(data[country]['legalAge']));
      } catch (err) {
        console.error('Error occurs during fetching location data: ', err);
      }
    };

    fetchProductData();
    fetchCultureData();
    fetchLocationData();
  }, []);

  const generateReferredProducts = (excludedIndex: number): Product[] => {
    return generateFourRandomProducts(products, excludedIndex);
  };

  const value = {
    products,
    cultures,
    locations,
    legalAge,
    generateReferredProducts,
  };

  return (
    <ProductsContext.Provider value={value}>
      {children}
    </ProductsContext.Provider>
  );
};

import './terms.styles.scss';

const Terms = () => {
  return (
    <div className='page-terms'>
      <h1 className='heading'>Terms and Conditions</h1>
      <p>
        The following describes the terms of use upon Jade Hare offers you
        access to its Website (the "Site") and the terms you accept by accessing
        the Site.
      </p>

      <h2>1. Age requirement</h2>
      <p>You must be 19 years or older to enter this Website.</p>

      <h2>2. User Submissions</h2>
      <p>
        Jade Hare Group LP is not responsible for monitoring, screening,
        policing, or editing the information posted or materials on, submitted
        to or accessed or transmitted through the Site. Jade Hare Group LP does
        not and cannot monitor all information or materials posted on, submitted
        to or transmitted through the Site. However, Jade Hare Group LP reserves
        the right to delete, block and/or remove any information or materials
        that Jade Hare Group LP, in our sole discretion, deem to be unlawful,
        infringing, defamatory, libelous, abusive, threatening, obscene,
        offensive, fraudulent, deceptive, inappropriate or otherwise
        unacceptable to Jade Hare Group LP or any other party. If notified of
        any such information or materials on the Site, Jade Hare Group LP will
        determine in its sole discretion whether to remove such content from the
        Site or repost such content to the Site at the time of notification or
        any other time in the future. You agree to grant to Jade Hare Group LP a
        non-exclusive, royalty-free, worldwide, perpetual license, with the
        right to sublicense, to reproduce, distribute, transmit, provide access
        to, make available, create derivative works of, publicly display and
        publicly perform any materials and other information (including, without
        limitation, ideas contained therein for new or improved products and
        services) you submit to public areas of the Site (such as bulletin or
        message boards, forums and newsgroups) by all means and in any media or
        any mode of distribution now known or hereafter developed. You also
        grant to Jade Hare Group LP the right to use your name and image in
        connection with the submitted materials and other information as well as
        in connection with all advertising, marketing and promotional material
        related thereto. You agree that you shall have no recourse against Jade
        Hare Group LP for any alleged or actual infringement or misappropriation
        of any proprietary or other right in your submissions, postings or
        communications to us.
      </p>

      <h2>3. Your usage of Site</h2>
      <p>
        You agree to use this Site only for lawful purposes, and that you are
        responsible for your use of, communications, and postings on the Site.
        You agree not to post on, provide access, make available, or transmit
        through this Site any unlawful, infringing, threatening, harassing,
        abusive, libelous, defamatory, vulgar, obscene, profane, indecent,
        offensive, hateful, pornographic or otherwise objectionable material of
        any kind, including without limitation any material that encourages or
        solicits criminal conduct or conduct that would give rise to civil
        liability, infringes others' intellectual property or other rights,
        discloses private or personal matters concerning any person, concerns
        products or items outside the scope of the particular bulletin board
        room, or otherwise violates any applicable local, state, national,
        foreign or international law including, without limitation, those
        relating to export control, consumer production, unfair competition,
        anti-discrimination or false advertising.
      </p>
      <p>
        You agree not to use or allow others to use this Site in any manner that
        interferes with its operation or with any other user's use and enjoyment
        of the Site. You agree not to post or transmit on the Site any file or
        code that contains viruses, worms, "Trojan horses" or any other
        contaminating or destructive features.
      </p>
      <p>
        You agree not to post or transmit on the Site any charity requests,
        petitions for signatures, chain letters, letters relating to pyramid
        schemes or similar documents. You agree not to post, provide access,
        make available or transmit on the Site any advertising, promotional
        materials or any other solicitation of other users of the Service for
        goods or services except in those areas that might expressly be
        designated by Jade Hare Group LP, from time to time, for such purposes.
      </p>
      <p>
        You agree that you will not access this Site from any territory where
        its use or contents is illegal, and that you, and neither Jade Hare
        Group LP nor our affiliates, are responsible for compliance with
        applicable local laws.
      </p>
      <p>
        You recognize that access to this site is limited to people 19 years.
        You agree to maintain the security of your PID on the Site, and other
        confidential information relating to the use of the Site and your PID on
        the Site. You agree to be responsible for all uses and/or charges
        resulting from the use of your account on the Site, including charges
        resulting from unauthorized use of your account prior to your taking
        steps to prevent such occurrence by changing your password and notifying
        us.
      </p>

      <h2>4. Chat/Bulletin Board policy</h2>
      <p>
        The Site may contain various chat/bulletin boards which act among other
        things as a venue for adult smokers to discuss issues related to tobacco
        use. As a result, Jade Hare Group LP has no control over the information
        provided by other users or the quality, safety or legality of the topics
        discussed or the truth or accuracy of the listing. Users should use
        caution and common sense when posting personal information on any web
        site. Because Jade Hare Group LP is not involved in any transactions, in
        the event of any dispute all users of the bulletin board release Jade
        Hare Group LP and each of its officers, directors, agents, subsidiaries
        and employees from claims, demands, and damages (actual and
        consequential) of every kind and of every nature, known and unknown,
        suspected and unsuspected, disclosed or undisclosed, arising out of or
        in any way connected with such dispute.
      </p>

      <h2>
        5. Termination of usage; Notification and procedure regarding
        infringements
      </h2>
      <p>
        You agree that Jade Hare Group LP may terminate or suspend your access
        to all or part of this Site, at any time and without notice, for any
        conduct that Jade Hare Group LP, in its sole discretion, believe is in
        violation of these Terms of Use or any applicable law or is harmful to
        the interests of another user, Jade Hare Group LP or its affiliates. It
        is Jade Hare Group LP's policy to terminate in appropriate circumstances
        the accounts of any subscriber who engages in conduct that is in
        violation of these Terms of Use or any applicable law or is harmful to
        the interests of another user, Jade Hare Group LP or its affiliates. It
        is Jade Hare Group LP's policy to respond promptly to claims of
        intellectual property infringement and other violations of law. Jade
        Hare Group LP will promptly investigate notices of alleged infringement
        and reserve the right to take appropriate actions under the Digital
        Millennium Copyright Act ("DMCA") and other applicable laws and
        regulations that may be enacted or promulgated from time to time. Upon
        receiving notification that complies or substantially complies with the
        DMCA, Jade Hare Group LP will act to delete or disable access to any
        material claimed to be infringing or claimed to be the subject of
        infringing activity. It is Jade Hare Group LP's policy to terminate, at
        any time and under appropriate circumstances, the access to the Site of
        subscribers and account holders who are repeat infringers. If you
        believe that the Site contains any material that infringes your rights
        under the copyright laws, you shall provide notification to the
        following designated agent: info@Jadeharegroup.com.
      </p>

      <h3>Such notification shall:</h3>
      <ol>
        <li>
          include an electronic or physical signature of the person authorized
          to act on behalf of the owner of the copyright interest
        </li>
        <li>
          contain a description of the copyrighted work that is alleged to have
          been infringed, including the electronic location, such as the URL
          (i.e., the web page address) where the copyrighted work exists, or a
          copy of the copyrighted work
        </li>
        <li>
          identify the URL or other specific location where the allegedly
          infringing material is located
        </li>
        <li>
          provide the address, telephone number, and e-mail address of the
          notifying party
        </li>
        <li>
          include a statement by you that you have a good faith belief that the
          use of the material is not authorized by the copyright owner, its
          agent, or the law
        </li>
        <li>
          and include a statement by you, made under penalty of perjury, that
          the information in the notification is accurate that you are the
          copyright owner or the owner's authorized agent. When Jade Hare Group
          LP deletes or disables access to any material alleged to be infringing
          under this policy, Jade Hare Group LP may attempt to contact the user
          who has posted such material in order to give that user an opportunity
          to respond to the notification, although Jade Hare Group LP makes no
          promise to do so. Any counter notifications submitted by such user
          will be furnished to the complaining party, who will be given an
          opportunity to seek judicial relief in accordance with the DMCA before
          Jade Hare Group LP replaces or restores access to any material as a
          result of any counter notifications.
        </li>
      </ol>

      <h2>6. No warranty</h2>
      <p>
        Jade Hare Group LP and its suppliers provide the Site and any related
        Services "as is" and without any warranty or condition, express, implied
        or statutory. Jade Hare Group LP and its suppliers specifically disclaim
        any implied warranties of title, merchantability, fitness for a
        particular purpose and non-infringement. Some states do not allow the
        disclaimer of implied warranties, so the foregoing disclaimer may not
        apply to you. This warranty gives you specific legal rights and you may
        also have other legal rights which vary from state to state.
      </p>

      <h2>7. Liability limit</h2>
      <p>
        In no event shall Jade Hare Group LP or its suppliers be liable for lost
        profits or any special, incidental or consequential damages arising out
        of or in connection with our Site, our Services or this agreement
        (however arising, including negligence).
      </p>

      <h2>8. Indemnity</h2>
      <p>
        You agree to indemnify and hold Jade Hare Group LP and its subsidiaries,
        affiliates, officers, directors, agents, and employees, harmless from
        any claim or demand, including reasonable attorneys' fees, made by any
        third party due to or arising out of your breach of this Agreement or
        the documents it incorporates by reference, or your violation of any law
        or the rights of a third party.
      </p>

      <h2>9. Legal compliance</h2>
      <p>
        You shall comply with all applicable laws, statutes, ordinances and
        regulations regarding your use of Jade Hare Group LP's Site and any
        related Services and your listing, purchase, solicitation of offers to
        purchase, and sale of items.
      </p>

      <h2>10. No agency</h2>
      <p>
        You and Jade Hare Group LP are independent contractors, and no agency,
        partnership, joint venture, employee-employer or franchise-franchisee
        relationship is intended or created.
      </p>

      <h2>11. General</h2>
      <p>
        This agreement shall be governed in all respects by the laws of the
        Canada. Jade Hare Group LP does not guarantee continuous, uninterrupted
        or secure access to its services, and operation of its Site may be
        interfered with by numerous factors outside of our control. If any
        provision of this Agreement is held to be invalid or unenforceable, such
        provision shall be struck and the remaining provisions shall be
        enforced. Headings are for reference purposes only and in no way define,
        limit, construe or describe the scope or extent of such section. Jade
        Hare Group LP's failure to act with respect to a breach by you or others
        does not waive our right to act with respect to subsequent or similar
        breaches. This Terms of Use sets forth the entire understanding and
        agreement between us with respect to the subject matter hereof.
      </p>

      <h2>12. Third party sites</h2>
      <p>
        This Site may produce automated search results or otherwise link you to
        other sites on the Internet. These sites may contain information or
        material that some people may find inappropriate or offensive. These
        other sites are not under the control of Jade Hare Group LP, and you
        acknowledge that Jade Hare Group LP is not responsible for the accuracy,
        copyright compliance, legality, decency or any other aspect of the
        content of such sites. The inclusion of such a link does not imply
        endorsement of the site by us or any association with its operators.
      </p>

      <h2>13. Taxes and duty fees, Import, Customs, and Freight</h2>
      <p>
        Jade Hare Group LP cannot provide information about import and customs
        regulations in the destination country. As the person who ordered the
        merchandise, you are personally responsible for being informed about and
        understanding the import and customs regulations that apply to Swedish
        snus and other types of tobacco in the destination country and the rules
        related to payment of any duties/fees/taxes that may be charged. It is
        the responsibility of the buyer to pay for all applicable taxes and duty
        fees related to their purchase from this site. Jade Hare Group LP will
        not refund you any duties/fees/taxes arising in your destination
        country.
      </p>

      <h2>14. Change of mind, cancellations and returns</h2>
      <p>
        Since Snus is a perishable good, we cannot accept change of mind
        cancellations or returns. If you get in touch with our customer service
        before you order has been dispatched, we may be able to issue a full
        refund. If it has been dispatched already, we can neither cancel or
        refund your order. The same holds true with returns, we will not issue
        refunds on returned merchandise.
      </p>

      <h2>15. Best price policy</h2>
      <p>
        Jade Hare Group LP always strives to offer the best price on the
        Internet in terms of total cost. If you should have reason to disagree
        with this, please get in touch with our customer service at
        info@Jadeharegroup.com.
      </p>
      <p>Jade Hare Group LP, respects the privacy of consumers.</p>
      <h3>We collect information from our consumers in an effort to:</h3>
      <ol>
        <li>Ensure we are communicating with qualified adult tobacco users</li>
        <li>Provide the best possible service to our consumers</li>
        <li>Enhance our consumers' on-line experience</li>
        <li>
          We consider the information about our consumers to be confidential and
          we do not sell or rent or transmit personal information or e-mail
          addresses to other organizations or entities without the customer's
          specific consent. We limit access to consumer information to only
          those employees and representatives that need to work with consumer
          information.
        </li>
      </ol>

      <h2>Email Address</h2>
      <p>
        Providing your email address is mandatory and will only be used by Jade
        Hare Group LP to communicate with you. We do not now, or plan to send
        promotional offers to our customers' email addresses without the
        customer's specific consent.
      </p>

      <h2>Cookies</h2>
      <p>
        Cookies are little pieces of information that a website stores on your
        machine so that it can recognize the same user when he or she returns.
        We use cookies to streamline the login process and to enable us to
        communicate with qualified smokers. If you are concerned about cookies
        please turn on your browser's security feature to "Prompt Before
        Accepting Cookies" to inspect the information that is being stored.
      </p>

      <h2>Mailing list removal</h2>
      <p>
        If at any time you wish to have your information removed from the active
        mailing list, please contact us at support@Jadeharegroup.com
      </p>
    </div>
  );
};

export default Terms;
